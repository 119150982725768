<template>
  <AppPage
    :title="$vuetify.lang.t('$vuetify.vet_detail')"
    container
    :loading="vetLoading"
  >
    <div class="vet-container" v-if="vet">
      <v-row>
        <v-col cols="4">
          <SingleImage :src="vet.image" max-height="100px" />
        </v-col>
        <v-col cols="8">
          <div class="vet-info">
            <h3>{{ vet.name }} ({{ vet.country }})</h3>
            <div class="icons">
              <v-icon color="primary" v-ripple size="1.3rem" @click="makeCall"
                >mdi-phone</v-icon
              >
              <v-icon size="1.3rem" color="primary" v-ripple @click="openMail">
                mdi-mail</v-icon
              >
              <v-icon
                size="1.3rem"
                color="primary"
                v-ripple
                @click="openMapDirection"
              >
                mdi-map-marker</v-icon
              >
            </div>
          </div>
        </v-col>
      </v-row>

      <p class="subtitle-1 my-5" v-if="categories">{{ categories }}</p>
      <div class="vet-timing">
        <p class="title">{{ $vuetify.lang.t("$vuetify.opening_hours") }}</p>
        <ul class="timing-list">
          <li class="timing-list-item" v-for="(time, key) in timing" :key="key">
            <div class="day capitalize">{{ key }}</div>
            <div
              class="closed error--text"
              v-if="!timing[key].from || !timing[key].to"
            >
              {{ $vuetify.lang.t("$vuetify.closed") }}
            </div>
            <div class="time" v-else>
              {{ timing[key].from }} - {{ timing[key].to }}
            </div>
          </li>
        </ul>
      </div>

      <p class="title">{{ $vuetify.lang.t("$vuetify.phone_number") }}</p>
      <p class="sm value primary--text" @click="makeCall">
        {{ vet.contact | trim(20, "...") }}
      </p>
      <p class="title">{{ $vuetify.lang.t("$vuetify.address") }}</p>
      <p class="sm value">{{ vet.address }}</p>

    </div>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const vetModule = createNamespacedHelpers("vet");

import SingleImage from "@/components/media/single-image";
import { DEVICE } from "@/constants";

export default {
  name: "vet-detail",
  components: {
    SingleImage,
  },
  computed: {
    ...vetModule.mapState(["vetLoading", "vet"]),
    PLATFORM() {
      if (!window.cordova) return false;
      return window.device.platform;
    },
    categories() {
      if (!this.vet) return false;
      const { categories } = this.vet;
      return categories.map((cat) => cat.category.name).join(", ");
    },
    timing() {
      if (!this.vet) return [];
      return this.vet.timing;
    },
  },
  methods: {
    ...vetModule.mapActions(["FETCH_VET_DETAILS"]),
    openInAppBrowser(url, options = "") {
      let target = "_system";
      if (!window.cordova) target = "_blank";
      window.open(url, target, options);
    },
    makeCall() {
      const { contact } = this.vet;
      if (!contact) return;
      const url = `tel:${contact}`;
      this.openInAppBrowser(url);
    },
    openMail() {
      try {
        const { email } = this.vet;
        if (!email) return;
        const url = `mailto:${email}`;
        this.openInAppBrowser(url);
      } catch (error) {
        console.log(error);
      }
    },
    openMapDirection() {
      try {
        const { latitude, longitude } = this.vet;
        let url = `https://www.google.com/maps/?q=${latitude},${longitude}`;
        if (window.cordova) {
          url = `maps://?q=${latitude},${longitude}`;
          if (this.PLATFORM === DEVICE.ANDROID) {
            url = `geo:0,0?q=${latitude},${longitude}`;
          }
        }

        this.openInAppBrowser(url, "location=yes");
      } catch (error) {
        console.log(error);
      }
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join("");
    },
  },
  created() {
    this.FETCH_VET_DETAILS(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.vet-info {
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    color: #1f2937;
  }

  .icons {
    button {
      padding: 6px;
      border-radius: 50%;
      background-color: #f5f5f5;
      margin-right: 1rem;
    }
  }
}

p.title {
  font-size: 14px !important;
  font-weight: bold;
  margin: 0;
}

.vet-timing {
  .timing-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 70%;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 12px;
      line-height: 19px;
      color: #000000;
      opacity: 0.8;
      margin-bottom: 5px;
    }
  }
}

p.sm {
  font-size: 12px;
}

.pets-list{
  list-style: none;
  margin: 0;
  padding: 0;
}

.pets-list li{
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 12px 12px 0;
  border-bottom: 0.5px solid #000000;
}

.pets-list li .image {
  width: 50px;
}

.pets-list li .image img {
  object-fit: cover;
  object-position: center;
  border-radius: 11px;
}

.pets-list li .content {
  width: calc(100% - 90px);
}

.pets-list li .content .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #010101;
}

.pets-list li .content .title {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #010101;
}

.pets-list li .action {
  width: 25px;
}

.show-all{
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
}
</style>
